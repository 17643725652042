$tableBorderColor: #9c9b9b;

.addIcon {
    width: 14px;
    vertical-align: middle;
    margin-right: 5px;
    margin-left: 5px;
}
input {
    border: 1px solid #cdcdcd;
    border-radius: 8px;
    padding: .6em .4em;
    margin-right: 8px;
}
.content {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin-top: 20px;
}
.table {
    margin-top: 20px;
    border-collapse: collapse;
    width: 100%;
    text-align: left;
    th {
        padding: 8px;
        border: 1px solid $tableBorderColor;
    }
    td {
        padding: 8px;
        border: 1px solid $tableBorderColor;
    }
}
.pushbox-labels {
    input {
        width: 100%;
        margin-bottom: 5px;
    }
}
.hidden {
  display: none;
}
.okayIcon {
  width: 14px;
  vertical-align: middle;
  margin-left: 5px;
}