
.box-container {

  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  .simple-box {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #cdcdcd;
    margin-bottom: 24px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    overflow: hidden;

    .exclamation {
      position: absolute;
      top: 0;
      left: 0;
      width: 2em;
      height: 2em;
      background-image: url(../assets/icons/exclamation.svg);
      background-repeat: no-repeat;
      //background-size: 95;
    }

    .stop {
      position: absolute;
      top: 0;
      right: 0;
      width: 2em;
      height: 2em;
      background-image: url(../assets/icons/stop.svg);
      background-repeat: no-repeat;
    }

    > a {
      width: 100%;
      padding-bottom: 56%;
      position: relative;
    }


    .overlay {

      position: absolute;
      display: flex;
      align-items: center;
      bottom: 0;
      min-height: 35%;
      max-height: 100%;
      width: 100%;
      background-color: rgba(255, 239, 213, .9);
      overflow: hidden;

      > span {
        display: block;
        width: 100%;
        color: black;
        font-weight: bold;
        font-size: 1.1em;
        padding: 8px;
        text-align: center;
      }
    }
  }
}


@media only screen and (min-width: 992px) {
  .box-container {

    .simple-box {

      box-sizing: content-box;
      width: 30%;

      .overlay {

      }
    }
  }
}


