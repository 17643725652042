
.react-autosuggest__suggestions-container {

  .drop-shadow {

    width: calc(100vw - 50px);
    background-color: white;
    -moz-box-shadow: 9px 9px 33px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 9px 9px 33px 0px rgba(0,0,0,0.75);
    box-shadow: 9px 9px 33px 0px rgba(0,0,0,0.75);
    border-radius: 8px;
  }



  .show-all {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 2em;

    span {
      font-size: 1.1em;
      padding-right: 2em;
      cursor: pointer;
      font-style: italic;
    }
    span:hover {
      text-decoration: underline;
    }
  }
}


.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  max-height: 50vh;
  overflow-y: auto;
}





.react-autosuggest__suggestion {
  list-style: none;

  border-bottom: 2px solid #cecece;

  .item {
    display: flex;
    padding: 4px 4px 0px 4px;
  }

  img {
    width: 100px;
    max-width: 100px;
  }

  .suggestion-title {
    display: flex;
    padding-left: 10px;
    align-items: center;
    font-size: 1.2em;
  }
}

.react-autosuggest__suggestion .item {
  background-color: white;

}
.react-autosuggest__suggestion .item:hover {
  background-color: #ececec;
  cursor: pointer;
}


@media only screen and (min-width: 992px) {


  .react-autosuggest__suggestions-container {

    .drop-shadow {
      font-size: 1em;
      width: 130%;
    }

    .show-all span {
      font-size: 1em;
    }
  }


  .react-autosuggest__suggestion {
    list-style: none;
    border-bottom: 2px solid #cecece;

    img {
      //width: 100px;
      //max-width: 100px;
    }

    .title {
      font-size: 1em;
    }

  }
}