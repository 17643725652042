.stats-table {
  width: 100%;
  max-height: calc(100vh - 100px);
  border-collapse: collapse;
  margin-top: 20px;
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  tfoot {
    background-color: #f2f2f2;
    td {
      font-weight: bold;
    };
  }
  tbody {
  }
  .fixedTop {
    position: sticky;
    top: 75px;
    background-color: white;
    z-index: 1;
  }

}
@media (max-width: 991px) {
  .stats-table {
    .fixedTop {
      position: sticky;
      top: 0px;
      background-color: white;
      z-index: 1;
    }
  }
}
#root {
  overflow-x: auto;
}