$paddingLevel1: 30px;
$paddingLevel2: 60px;

$grey: #9c9b9b;



.edit-site-globals {

  textarea {
    width: 80%;
    resize: none;
    min-height: 120px;
  }

  .add-submenu-container {
    display: flex;
    align-items: center;

    > div:first-child {
      padding-right: 12px;
      font-weight: bold;
    }


  }

  .cookie-consent-settings {
    .label {
      font-weight: bold;
    }

    .wrapper {
      border: 1px solid $grey;
      border-radius: 8px;
      padding: 0;
    }

    table {
      .col-1 {
        width: 100px;
        text-align: left;
      }
      td input, td textarea {
        width: 100% !important;
      }
    }
  }

  .banner-settings {
    .label {
      font-weight: bold;
    }
    img {
      max-width: 200px;
    }
    table {
      td:first-child {
        width: 10em;
      }
      td:last-child {
        text-align: left;
      }
    }
  }



  .edit-menu-container {

    padding: 0 0 0 16px;

    .react-autosuggest__input {
      position: relative;
      z-index: 3;

    }

    .react-autosuggest__suggestion-container {
      position: relative;
    }

    .react-autosuggest__suggestions-container .drop-shadow {
      width: 100% !important;
      position: absolute;
    }

    li.react-autosuggest__suggestion {
      border: none;
      border-radius: 0;
      margin-bottom: 0;
      border-bottom: 2px solid #cecece;
    }

    li {
      list-style: none;
      border: 1px solid $grey;
      border-radius: 8px;
      margin-bottom: 6px;

      .container {
        display: flex;
        width: 100%;

        .left {
          min-height: 40px;
        }

        .left.level-1 {
          min-width: $paddingLevel1;
          background-color: #fbdab0;
        }

        .left.level-2 {
          min-width: $paddingLevel2;
          background-color: #5a3825;
        }

        .right {
          display: flex;
          max-width: 100%;
          width: 100%;
        }

        .right.level-1 {
          //padding-left: $paddingLevel1;
        }

        .right.level-2 {
          //padding-left: $paddingLevel2;
        }


        .right > table {
          td {
            padding-bottom: 0;
            width: 45px;
            height: 50px;
          }

          td input {
            width: 100%;
          }

          td.auto-width {
            width: auto;
          }

          td.label {
            font-weight: bold;
            text-align: right;
            text-transform: lowercase;
          }


          tr:first-child {

          }

          tr:nth-child(2) {

          }

          tr:nth-child(3) {

          }
        }

      }
    }
  }
}