.edit.production-seats {

    .label {
      font-weight: bold;
    }
  
    .margin {
      min-height: 70px;
    }
  
    .seat-list {
      padding-top: 20px;
      padding-bottom: 16px;
    }

    .seat-item {
      border: 1px solid #cdcdcd;
      border-radius: 8px;
      padding: 16px;
      margin-bottom: 20px;
      width: 100%;
      display: flex;
    }

    .half {
      width: 50%;
      margin-bottom: 8px;
    }

    .third {
      width: 33.3333%;
      margin-bottom: 8px;
      &.left {
        padding-right: 8px;
      }
      &.right {
        padding-left: 8px;
      }
    }

    .quart {
      position: relative;
      width: 25%;
      margin-bottom: 8px;
      &.left {
        padding-right: 8px;
      }
      &.right {
        padding-left: 8px;
      }
    }

    .half.left {
      padding-right: 8px;
    }

    .full {
      width: 100%;
      margin-bottom: 8px;
    }

    .small {
      width: 40px;
      margin-bottom: 8px;
    }

    .big {
      width: calc(100% - 80px);
      margin-bottom: 8px;
    }

    .flex {
      display: flex;
      justify-content: space-evenly;
    }

    .conti {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        border: 1px solid #cdcdcd;
        border-radius: 8px;
        margin-bottom: 12px;
        padding: 12px;
    }
    
    input {
        width: 100%;
        border: 1px solid #cdcdcd;
        border-radius: 8px;
        padding: .6em .4em;
    }
    
    .label {
        font-weight: bold;
        margin-bottom: 4px;
    }
    .toright {
        text-align: right;
        align-self: flex-end;
        button {
            margin-left: 4px;
        }
    }
    .disable {
        button {
            cursor: not-allowed;
            pointer-events: none;
            background-color: #f0f0f0;
            color: #808080;
            border: 1px solid #808080;
        }
    }
}