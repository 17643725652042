$tableBorderColor: #9c9b9b;



.edit-editors {

  .user-list {

    margin-top: 80px;
    border-collapse: collapse;
    width: 100%;

    tr.empty-row td:nth-child(1) {
      border: none;
      height: 12px;
    }

    tr.user-row {
      td {
        padding: 0;
      }

      td > div {
        width: 100%;
        height: 100%;
        font-size: 1em;
        border: 1px solid $tableBorderColor;
        border-left: none;
        padding: 4px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      td:nth-child(1) div {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-left: 1px solid $tableBorderColor;
      }

      td:nth-child(3) div {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      td:nth-child(5) {
        padding: 0 4px;
        min-width: 40px;
      }



      td:nth-child(1),
      td:nth-child(2) {
        width: 18%;
      }

      td:nth-child(4) {
        width: 40px;
        text-align: center;

        img {
          border-radius: 50%;
          width: 28px;
          height: 28px;
        }
      }

      td:nth-child(5) {
        width: 14%;

        select {
          width: 100%;
        }
      }

      td {

        select {
          width: 100%;
        }
      }
    }
    
    tr.fullname-row {
      td {
        padding: 0;
      }

      td > div {
        width: 100%;
        height: 100%;
        font-size: 1em;
        border: 1px solid $tableBorderColor;
        border-left: none;
        padding: 4px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      td:nth-child(1) div {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-left: 1px solid $tableBorderColor;
      }

      td:nth-child(2) div {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      td:nth-child(4) {
        padding: 0 4px;
        min-width: 40px;
      }



      td:nth-child(1) {
        width: 36%;
      }

      td:nth-child(3) {
        width: 40px;
        text-align: center;

        img {
          border-radius: 50%;
          width: 28px;
          height: 28px;
        }
      }

      td:nth-child(4) {
        width: 14%;

        select {
          width: 100%;
        }
      }

      td {

        select {
          width: 100%;
        }
      }
    }

  }
}
.hidden {
  display: none;
}
.okayIcon {
  width: 14px;
  vertical-align: middle;
  margin-left: 5px;
}