.edit.sheets {

  .label {
    font-weight: bold;
  }

  .margin {
    min-height: 70px;
  }

  .sheets-list {
    padding-top: 20px;
    padding-bottom: 16px;
  }

  .sheet-item {
    border: 1px solid #cdcdcd;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 20px;
    width: 100%;
    display: flex;

    .left {
      width: calc(100% - 450px);
    }

    .left > div {
      margin-bottom: 8px;
    }
    .left > div:last-child {
      margin-bottom: 0;
    }

    .left input {
      width: 400px;
      min-width: 200px;
    }

    .right {
      text-align: right;
      align-self: flex-end;
      width: 450px;

      button {
        margin-left: 4px;
      }
    }
  }


  .cloud-list {
    margin-top: 12px;
    border: 1px solid #cdcdcd;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .cloud-section {
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid #cdcdcd;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 12px;
  }

  .section-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;

    span {
      text-transform: uppercase;
      font-size: 1.1em;
      padding-right: 30px;
      background-image: url(../assets/icons/chevron-down.svg);
      background-repeat: no-repeat;
      background-position-x: right;
      background-position-y: center;
    }

    span.open {
      background-image: url(../assets/icons/chevron-up.svg);
    }
  }

  .tag-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .tag-item {
    padding: 2px 4px;
    border: 1px solid black;
    color: black;
    cursor: pointer;
    border-radius: 4px;
    margin: 8px 8px;
    background-color: #ffefd5;
    user-select: none;
  }

  .tag-item.selected {
    color: #ffefd5;
    background: #e84e1b;
  }

  img.redirect {
    max-height: 24px;
  }

}