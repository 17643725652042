$level0Indent: 30px;

.tag {

  .tag-link-icon {
    padding-left: 35px;
    background-image: url(../assets/icons/move-right.svg);
    background-position: right 2px center;
    background-repeat: no-repeat;
    background-size: 1em;
    height: 30px;
  }

  .levels {

    display: block;
    .level-0, .level-1, .level-2, .level-3 {
      width: calc(100% + 2 * 24px);
      margin-left: -24px;
      padding: 12px 24px;

    }

    h3 a {
      text-decoration: none;
      color: #2c1608;
    }

    a.underline {
      text-decoration: underline;
    }
    a.no-underline-hover:hover {
      text-decoration: none;
    }

    .level-1 h3 {
      background-size: 1.1em;
    }
    .level-2 h3 {
      background-size: 1.0em;
    }
    .level-3 h3 {
      background-size: .9em;
    }

    .level-1, .level-2, .level-3 {
      h3 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
      }

      h3.tag-link {
        padding-right: 50px;
        background-position: right 4px center;
        background-repeat: no-repeat;
        background-image: url(../assets/icons/move-right.svg);
      }

      h3.tag-link:hover {
        text-decoration: underline;
      }
    }

    .level-0 {
      padding-left: 40px + $level0Indent;
      margin-bottom: 16px;
      padding-top: 8px;
      padding-bottom: 8px;

      h3 {
        font-weight: normal;
        font-size: 1.2em;
        margin-bottom: 4px;
        padding-right: 50px;
        margin-top: 0;
      }

      div.link-icon {
        cursor: pointer;
        float: right;
        height: 2em;
        padding-left: 40px;
        background-image: url(../assets/icons/move-right.svg);
        background-position: right 4px center;
        background-repeat: no-repeat;
        background-size: 1em;
      }

      .image {
        position: relative;
        text-align: center;
        width: 100%;
        margin-bottom: 12px;

        .exclamation {
          position: absolute;
          top: 0;
          left: 0;
          width: 2em;
          height: 2em;
          background-image: url(../assets/icons/exclamation.svg);
          background-repeat: no-repeat;
          //background-size: 95;
        }

        .stop {
          position: absolute;
          top: 0;
          right: 0;
          width: 2em;
          height: 2em;
          background-image: url(../assets/icons/stop.svg);
          background-repeat: no-repeat;
        }

        &.small {
          text-align: left;
          margin-bottom: 0px;
          .stop,
          .exclamation {
            top: 0px;
            position: relative;
            margin-right: 8px;
            display: inline-block;
          }
        }

      }
      .image > img {
        border: 1px solid #9c9c9c;
        border-radius: 10px;
        width: 100%;
        margin: 0;
        padding: 0;
      }
      .image > a {
        width: 100%;
        margin: 0;
        padding: 0;
        display: block;
        img {
          border: 1px solid #9c9c9c;
          border-radius: 10px;
          width: 100%;
          margin: 0;
          padding: 0;
        }
      }

      .info {

        .description {
          margin-bottom: 24px;
        }

        .labels {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: flex-start;
          margin-bottom: 10px;
        }

        .labels .prod-status {
          margin-right: 10px;
        }

        .labels > span {
          margin-right: 12px;
          margin-bottom: 12px;
        }
        .labels > span:last-child {
          margin-right: 0;
        }
      }
    }

    .level-1 {
      background-color: #fbf7f2;
      h3 {
        padding-left: $level0Indent;
      }
    }

    .level-2 {
      background-color: #ffefd5;
      h3 {
        font-size: 22px;
        font-weight: bold;
      }
    }

    .level-3 {
      background-color: #F9D1AA;
      h3 {
        font-size: 26px;
        font-weight: bold;
      }
    }

  }
}

@media only screen and (min-width: 992px) {

  .tag {

    .search-wrapper {
      text-align: right;
    }
    .search-wrapper input {
      width: 400px;
    }

    .levels {

      .level-0 {

        .production-container.with-image {
          display: flex;
          align-items: flex-start;
        }
        .production-container:not(.with-image) {
          .info {
            padding-left: 0px;
          }
        }

        .info {
          padding-left: 12px;
        }

        .image {
          width: 256px;

          .stop,
          .exclamation {
            top: 16px;
          }

          &.small {
            text-align: left;
            .stop,
            .exclamation {
              top: 0px;
              position: relative;
              margin-right: 8px;
              display: inline-block;
            }
          }
          //margin-bottom: 12px;
        }

        .image > img {
          border: 1px solid #9c9c9c;
          border-radius: 10px;
          width: auto;
          max-width: 256px;
          margin: 16px 0 0 0;
          padding: 0;
        }
        .image > a {
          width: auto;
          max-width: 256px;
          padding: 0;
          display: block;
          img {
            border: 1px solid #9c9c9c;
            border-radius: 10px;
            width: 100%;
            margin: 0;
            padding: 0;
          }
        }
      }
    }

  }
}
.searchbox-labels {
  .labels {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;

    &>span {
      margin-bottom: 12px;
      margin-right: 12px;
  }
  }
}