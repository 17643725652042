//background-color: #fbf7f2;
$orange: #e84e1b;

.app .row-content {

  position: relative;

  .read, .edit {
    padding: 12px;
  }

  .read {
    background-color: #fbf7f2;

    span.article-info {
      font-size: .8em;
    }

    img {
      padding: 10px;
      border: none;
    }

    img.status-img {
      margin: 0;
      padding: 0;
    }
    /*
    .image-style-side {
      float: right;
    }
    */

  }


  .edit {
    div.title {
      height: 50px;
      background-color: $orange;
      position: absolute;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      > div {
        color: $orange;
        font-size: 1.3em;
        line-height: 1.4em;
        background-color: #ffefd5;
        height: 1.4em;
        padding: 0 14px;
        border-radius: .65em;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .ckeditor > label {
      display: block;
      font-weight: bold;
      padding-bottom: 6px;
      text-transform: lowercase;
    }


    .copy-to-clipboard {
      width: 1.8em;
      margin-bottom: -.9em;
      margin-left: .6em;
      cursor: pointer;
    }

    .control-buttons {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      padding: 1em 0;

      > button {
        margin-left: 2em;
      }
    }

    .history {
      p {
        font-size: 1.2em;
        font-weight: bold;
        text-transform: lowercase;
      }
      li {
        list-style: none;
        margin-bottom: 4px;
      }
    }

    form {
      margin-top: 80px;



      input {
        border: 1px solid #cdcdcd;
        border-radius: 8px;
        padding: .6em .4em;
      }

      .input-with-icon > input {
        background-position: right 4px center;
        background-repeat: no-repeat;
        background-size: 24px;
        padding-right: 40px;
        overflow: hidden;
      }
      .input-with-icon.untouched > input {
        background-image: url('../assets/icons/url-untouched.svg');
      }
      .input-with-icon.valid > input {
        background-image: url('../assets/icons/url-valid.svg');
      }
      .input-with-icon.invalid > input {
        background-image: url('../assets/icons/url-invalid.svg');
      }

      span.error {
        color: #e71a30;
      }

      table {
        width: 100%;

        td {
          padding-bottom: 1.3em;
        }

        td:first-child {
          font-weight: bold;
          vertical-align: top;
          padding-top: .5em;
        }

        td input {
          width: 80%;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {

  .app .row-content {

    .read {
      padding: 12px 24px;
    }


  }
}
.edit-single-tag {
  .react-autosuggest__container {
    position: relative;
  }
}