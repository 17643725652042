$red: #E20613;

.production {

  .labels {
    margin-bottom: 2em;

    > span {
      margin-right: 1em;
    }
  }

  span.empty {
    padding: 2px 4px;
    border: 2px dashed $red;
    text-transform: uppercase;
    color: $red;
  }

  span.hint {
    font-size: .8em;
    display: block;
    margin-top: -1.1em;
  }

  .empty-content {
    min-height: 150px;
    position: relative;
    border: 4px dashed $red;
    display: flex;
    align-items: center;
    justify-content: center;

    > div.alert {
      color: $red;
      font-weight: bold;
    }

    > div.title {
      color: #9c9b9b;
      position: absolute;
      left: 1em;
      top: 1em;
    }

  }

  .instances.read {
    margin-bottom: 2em;
    margin-left: -24px;
    margin-right: -24px;
    padding: 8px 24px;
    background-color: #936e61;

  }

  .instances {
    .short-description {
      min-width: 100%;
      padding-top: 1rem;
      padding-left: 1rem;
      font-size: 1rem;
    }

    h2 {
      padding-left: 16px;
      color: white;
      font-size: 1.3em;
    }
  }

  .instance {
    display: flex;
    color: white;
    flex-direction: column;
    margin-left: 1.9em;
    margin-bottom: 1.2em !important;
    font-size: 1.1em;

    a {
      color: white;
      text-decoration: none;
    }
    a:hover {
      text-decoration: none;
    }

    .time {
      font-size: .9em;
      color: white;
      font-style: italic;
      white-space: nowrap;
    }

    .status {
      margin-top: 1em;
      > div {
        margin-bottom: .4em;
      }
    }
  }

  .cover {
    img {
      border: 1px solid #9c9c9c;
      border-radius: 6px;
      max-width: 100%;
      width: 100%;
    }
  }

  .creators-container {
    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: 24px;
    background-color: #936e61;
    padding: 12px 24px;
    color: white;

    h3 {
      font-size: 1.3em;
      margin-bottom: 8px;
    }

    a {
      color: white;
    }
    a:hover {
      text-decoration: none;
    }
  }


  .small-description,
  .cover,
  .description,
  .documents,
  .notes,
  .creators {
    margin-bottom: 2em;
  }

  .documents {
    .item {
      display: flex;

      > div:first-child {
        margin-right: 4px;
      }
    }
  }

}

@media only screen and (min-width: 992px) {
  .production {

    .instance {

      .prod-status {
        padding-right: 8px;
      }

      margin-bottom: 0;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      > div:first-child {
        //min-width: 450px;
        margin-right: 8px;
      }

      .status {
        display: flex;
        > div {
          margin-right: .5em;
        }
      }
    }
  }
}



.production.edit {

  label { font-weight: bold; }

  .description {
    margin-bottom: 32px;
  }

  .creators >div:first-child {
    font-weight: bold;
  }

  table.creators {

    td:nth-child(1),
    td:nth-child(2),
    td:nth-child(4),
    tr:last-child > td:first-child {
      width: 40px;
      padding: 0 0 !important;
      vertical-align: top !important;
    }


    input {
      width: 100% !important;
    }
  }



  .notices {
    margin-bottom: 32px;
  }



  .documents {

    .ckfinder {
      width: 40px;
    }

    > div:first-child {
      font-weight: bold;
    }

    .item {
      display: flex;
      align-items: center;

      > div:first-child {
        width: 30px;
      }

      > div:nth-child(2) {
        margin-right: 16px;
      }
    }
  }

  img {
    max-width: 160px;
  }

  .image {
    display: flex;

  }
  .image .title {
    font-weight: bold;
  }


  .instance-container {

    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    border: 1px solid #cdcdcd;
    border-radius: 8px;
    margin-bottom: 12px;
    padding: 12px;



    div.label {
      font-weight: bold;
      margin-bottom: 4px;
    }

    input,
    .react-datepicker-wrapper {
      width: 100%;
    }

    textarea {
      width: 100%;
      min-height: 120px;
    }

    .half {
      width: 50%;
      margin-bottom: 8px;
    }

    .quart {
      position: relative;
      width: 25%;
      margin-bottom: 8px;
      &.left {
        padding-right: 8px;
      }
      &.right {
        padding-left: 8px;
      }
    }

    .half.left {
      padding-right: 8px;
    }

    .full {
      width: 100%;
      margin-bottom: 8px;
    }

    .small {
      width: 40px;
      margin-bottom: 8px;
    }

    .big {
      width: calc(100% - 80px);
      margin-bottom: 8px;
    }

    .flex {
      display: flex;
      justify-content: space-evenly;
    }

    .checkboxes {
      //width: calc(100% / 3);
    }


    .checkbox {
      display: inline-flex;
      cursor: pointer;
      position: relative;
    }

  }

  .react-autosuggest__suggestions-container .drop-shadow {
    width: 100%;
  }

  .autosuggestion-cell {
    position: relative;
    height: 70px;

    .hint {
      width: 100%;
      position: absolute;
      bottom: 5px;
    }
  }
}
.disable {
    button {
        cursor: not-allowed;
        pointer-events: none;
        background-color: #f0f0f0;
        color: #808080;
        border: 1px solid #808080;
    }
}