
/* ------ */
/* loader */
/* ------ */
.loader-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0);
}

.loader-container.show {
  .loader {
    opacity: 1;
  }
}

.loader {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
  top: 50%;
  opacity: 0;
  transition: opacity 200ms ease-out 300ms;
}

#loader span{
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: #b0b0b0;
  margin: 35px 5px;
}

#loader span:nth-child(1){
  animation: bounce 1s ease-in-out infinite;
}

#loader span:nth-child(2){
  animation: bounce 1s ease-in-out 0.33s infinite;
}

#loader span:nth-child(3){
  animation: bounce 1s ease-in-out 0.66s infinite;
}

@keyframes bounce{
  0%, 75%, 100%{
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  25%{
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}