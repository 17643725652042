
label.checkbox {
  display: inline-flex;
  margin: 0 1rem 0.5rem 0px;
}
img.add {
  padding: 0 !important;
  height: 1.2rem;
  vertical-align: middle;
  margin: 0 0 0 0.5rem;
  cursor: pointer;
  transition: transform 0.2s;
}
img.add:hover {
  transform: rotate(1turn);
}