
.app {

  /** MOBILE **/
  .row-menu.mobile .main-menu {

    z-index: 10;
    position: relative;

    .hamburger {
      position: absolute;
      top: 26px;
      right: 0;
      z-index: 2;
      padding: 2px 25px 2px 0;

      span {
        display: block;
        width: 33px;
        height: 4px;
        margin-bottom: 5px;
        position: relative;

        background: black;
        border-radius: 3px;

        transform-origin: 4px 0;

        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
        background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
        opacity 0.55s ease;
      }

      span:first-child {
        transform-origin: 0% 0%;
      }

      span:nth-last-child(2) {
        transform-origin: 0% 100%;
      }
    }


    .hamburger.opened {
      span {
        opacity: 1;
        transform: rotate(45deg) translate(0px, -20px);
        background: #232323;
      }

      span:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }

      span:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, 20px);
      }
    }


    .overlay.opened {
      left: 0;
      bottom: 0;

      .search input {
        opacity: 1;
      }
    }

    .overlay {

      position: fixed;
      left: 100vw;
      top: 48px;
      bottom: 0;
      width: 100vw;
      padding: 0;
      margin: 0;
      transition: left 300ms ease;
      background-color: #FFEFD5;
      z-index: 1;

      div, span, li, a {
        font-family: 'Montserrat', sans-serif;
      }


      .container {
        z-index: 1;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 68px;
        overflow-y: auto;
        margin: 0;
        padding: 0;

        ul {
          padding-left: 0;
        }

        li {
          list-style: none;
          padding-left: 2rem;
          padding-right: 4rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
          user-select: none;
        }

        li.level-0 {
          padding-bottom: 1.2em;
          padding-top: 1.2em;
          font-size: 1.5em;
          color: #2C1608;
        }

        a,
        a:hover {
          text-decoration: none;
        }

        li.level-0 a {
          color: #2C1608;
        }

        li.level-0.arrow {
          background-size: 1em 1em;
          background-position: right 2rem center;
          background-repeat: no-repeat;
        }
        li.level-0.closed {
          background-image: url('../assets/icons/chevron-down.svg');
        }
        li.level-0.opened {
          font-weight: bold;
          background-image: url('../assets/icons/chevron-up-brown.svg');
        }

        li.level-1 {
          background-color: #F9D1AA;
          color: #5A3825;
          font-size: 1.3em;
          padding-top: .75em;
          padding-bottom: .75em;
          padding-left: 2.8rem;
        }
        li.level-1 a {
          color: #5A3825;
        }
        li.level-1.arrow {
          background-size: 1em 1em;
          background-position: right 2rem center;
          background-repeat: no-repeat;
        }
        li.level-1.closed {
          background-image: url('../assets/icons/chevron-down-brown.svg');
        }
        li.level-1.opened {
          font-weight: bold;
          background-image: url('../assets/icons/chevron-up-brown.svg');
        }

        li.level-2 {
          background-color: #936E61;
          color: #FFFFFF;
          font-size: 1.1em;
          padding-top: .6em;
          padding-bottom: .6em;
          padding-left: 3.2em;
        }
        li.level-2 a {
          color: #FFFFFF;
        }
      }
    }

  }






  /** BROWSER **/
  .row-menu.browser .main-menu {

    span, div, a {
      font-family: 'Montserrat', sans-serif;
    }

    a,
    a:hover {
      text-decoration: none;
    }

    .level-0.container {

      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 18px;
      width: 100%;

      .level-1.container,
      .level-2.container {
        display: none;
      }


      .item.level-0 {
        position: relative;
        color: #2C1608;
        margin-left: 26px;
      }
      .item.level-0 a {
        color: #2C1608;
      }

      .item.level-0 > .label {
        font-size: 1.1em;
        white-space: nowrap;
      }

      .item.level-0:hover > .label {
        font-weight: bold;
        color: #5A3825;
      }
      .item.level-0:hover > .label > span {
        border-bottom: 3px solid rgba(0, 0, 0, 0);
      }

      .item.level-1,
      .item.level-2 {
        width: 100%;
        padding: 8px 12px;
        position: relative;

        > .label {
          //white-space: nowrap;
          //overflow: hidden;
          //text-overflow: ellipsis;
        }
      }

      // @TODO test, hogy lenyiljon
      //.item.level-0 > .level-1.container,

      .item.level-0:hover > .level-1.container,
      .level-1.container:hover,
      .level-2.container:hover{

        position: absolute;
        top: 24px;
        display: flex;
        flex-direction: column;
        width: 230px;
        background-color: #F9D1AA;

        .item.level-1,
        .item.level-1 a {
          color: #5A3825;
        }

        .item.level-1:hover,
        .item.level-1:hover a,
        .item.level-1:hover a:hover {
          background-color: #936E61;
          color: white;
        }

        // @TODO test, hogy lenyiljon
        //.item.level-1 > .level-2.container,

        .item.level-1:hover > .level-2.container,
        .level-2.container:hover {
          position: absolute;
          display: flex;
          flex-direction: column;
          width: 230px;
          background-color: #936E61;
          color: white;
          top: 0;
          left: 0;
          transform: translateX(100%);

          .item.level-2:hover,
          .item.level-2:hover a,
          .item.level-2:hover a:hover {
            background-color: #DBB491;
            color: #5A3825;
          }
        }
      }

      .item.level-0:nth-last-child(-n+4) .level-2.container {
        transform: translateX(-100%) !important;
      }


      .item.level-0:last-child .level-1.container {
        right: 0;
      }
    }

  }

}