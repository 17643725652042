$linkColor: #1D70B7;
$buttonGreen: #008D36;
$buttonRed: #E20613;
$buttonBlue: #1D70B7;
$buttonDisabled: #cccccc;



.link {
  color: $linkColor;
  text-decoration: underline;
  cursor: pointer;
}

.link:hover {
  text-decoration: none;
}

.button {
  border-radius: .5em;
  border-width: 2px;
  border-style: solid;
  border-color: $buttonGreen;
  color: $buttonGreen;
  text-transform: uppercase;
  padding: .2em .5em;
  background-color: rgba(0,0,0,0);
  font-weight: bold;
  cursor: pointer;
  &.mr-1 {
    margin-right: 1em;
  }
  &.mt-5px {
    margin-top: 5px;
  }
  &.right {
    float: right;
  }
}

.button.disabled {
  border-color: $buttonDisabled;
  color: $buttonDisabled;
  cursor: not-allowed;
}

.button.green {
  border-color: $buttonGreen;
  color: $buttonGreen;
}
.button.green:hover {
  background-color: $buttonGreen;
  border-color: $buttonGreen;
  color: white;
}

.button.red {
  border-color: $buttonRed;
  color: $buttonRed;
}
.button.red:hover {
  background-color: $buttonRed;
  border-color: $buttonRed;
  color: white;
}

.button.blue {
  border-color: $buttonBlue;
  color: $buttonBlue;
}
.button.blue:hover {
  background-color: $buttonBlue;
  border-color: $buttonBlue;
  color: white;
}



.colored-label {
  border: 1px solid black;
  border-radius: .5em;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 600;
  padding: .1em .5em;
  font-size: .9em;

  a, a:hover {
    color: inherit !important;
    text-decoration: none;
  }
}





.prod-status > img {
  height: 1.5em;
}






@mixin bgSetup {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 22px;
}

.icon {
  min-width: 32px;
  min-height: 32px;
}

.icon:hover {
  cursor: pointer;
}

.icon.disabled:hover {
  cursor: default;
}

.icon.move-up {
  @include bgSetup;
  background-image: url('../assets/icons/move-up.svg');
}
.icon.move-up:hover {
  background-image: url('../assets/icons/move-up-hover.svg');
}
.icon.move-up.disabled {
  background-image: url('../assets/icons/move-up-disabled.svg');
}

.icon.move-down {
  @include bgSetup;
  background-image: url('../assets/icons/move-down.svg');
}
.icon.move-down:hover {
  background-image: url('../assets/icons/move-down-hover.svg');
}
.icon.move-down.disabled {
  background-image: url('../assets/icons/move-down-disabled.svg');
}

.icon.add,
.icon.add-submenu {
  @include bgSetup;
  background-image: url('../assets/icons/add.svg');
}
.icon.add:hover,
.icon.add-submenu:hover {
  background-image: url('../assets/icons/add-hover.svg');
}

.icon.remove {
  @include bgSetup;
  background-image: url('../assets/icons/remove.svg');
}
.icon.remove:hover {
  background-image: url('../assets/icons/remove-hover.svg');
}

.icon.opened {
  @include bgSetup;
  background-image: url('../assets/icons/chevron-up.svg');
}

.icon.closed {
  @include bgSetup;
  background-image: url('../assets/icons/chevron-down.svg');
}

.icon.paperclip {
  @include bgSetup;
  min-width: 16px;
  min-height: 16px;
  background-size: 14px;
  background-image: url('../assets/icons/paperclip.svg');
}

.icon.checked {
  @include bgSetup;
  background-image: url('../assets/icons/checkbox-checked.svg');
  background-size: 13px;
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
  border: 1px solid black;
}

.icon.unchecked {
  @include bgSetup;
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
  border: 1px solid black;
}

.toggle {
  border: 1px solid #cdcdcd;
  &.unchecked{
    display: flex;
    align-items: center;
    width: 50px;
    height: 24px;
    border-radius: 12px;
    justify-content: flex-start;
    background-color: #fff;
  }
  &.checked{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50px;
    height: 24px;
    border-radius: 12px;
    background-color: #fff;
  }
  .slider {
    width: 18px;
    height: 18px;
    background-color: #66cc99;
    border-radius: 50%;
    margin: 0 4px;
  }
}
.fakeinput {
  display: inline-block;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 8px;
  padding: .6em .4em;
  background-color: transparent;
  font-size: 13.3333px;
}
.itsRed {
  .fakeinput {
    color: #e71a30;
  }
  .label {
    color: #e71a30;
  }
}