$lightBg: #ffefd5;
$titleRed: #e71a30;
$grey: #b0b0b0;



* {
  font-family: 'Cabin', sans-serif;
  box-sizing: border-box;
}

html {
  font-size: 14px;
}

body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  color: rgb(44, 22, 8);
}

a {
  color: rgb(45, 46, 130);
}

h2, h3 {
  text-transform: uppercase;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

td {
  padding: 2px 4px;
}

input {
  padding: 4px;
}

.bold {
  font-weight: bold;
}


.with-image > image > img {
  margin: 0;
}



@media only screen and (min-width: 1200px) {
  body {
    background-image: url('../assets/background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

@media only screen and (min-width: 992px) {
  html {
    font-size: 16px;
  }
}


.pointer {
  cursor: pointer;
}
.pointer a:not([href]) {
  cursor: default;
}
.underline {
  text-decoration: underline;
}
.underline-hover:hover {
  text-decoration: underline;
}
.no-underline-hover:hover {
  text-decoration: none;
}

.search-wrapper {
  margin: 10px 0 30px;

  input {
    font-size: 1.08em;
    width: 100%;
    padding: .35em 35px .35em 10px;
    border: 1px solid #cdcdcd;
    border-radius: 8px;
    background-image: url(../assets/icons/search.svg);
    background-repeat: no-repeat;
    background-position: right 6px center;
    background-size: 22px 22px;
    text-align: left;
  }
}
.search-wrapper.mobile-search {
  margin: 1.3em 7em 0 28px;
  width: calc(100% - 7em);

  input {
    font-size: 1.08em;
    width: 100%;
    padding: .35em 10px .35em 35px;
    border: 1px solid #cdcdcd;
    border-radius: 8px;
    background-image: url(../assets/icons/search.svg);
    background-repeat: no-repeat;
    background-position: left 6px center;
    background-size: 16px 16px;
    text-align: left;
  }
}

.overlay .search-wrapper.mobile-search {
  position: relative;
  z-index: 3;
}





#root {
  padding: 0;
  margin: 0;
  max-width: 100vw;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

/* mobil css */
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;

  .row-menu,
  .row-content,
  .row-footer {
    width: 100%;
    background-color: $lightBg;
  }

  .row-content {
    min-height: 40vh;
  }

  .row-menu {
    z-index: 10 !important;
  }

  .row-menu.browser {
    display: none;
  }

  .row-info {
    display: flex;
    width: 100vw;
    height: 76px;
    background-color: $lightBg;

    .logo {
      width: 15vw;
      max-width: 80px;
      padding-left: 25px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      > img {
        width: 100%;
        height: auto;
      }
    }

    .title {
      width: 70vw;

      h2 {
        color: $titleRed;
        font-family: 'Montserrat', sans-serif;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 1.2em;
        text-align: center;
        margin-top: 18px;
        margin-bottom: 0;
      }

      p {
        color: #1d1d1b;
        font-family: 'Montserrat', sans-serif;
        margin-top: 0;
        text-transform: uppercase;
        font-size: .7em;
        text-align: center;
      }
    }

    div:nth-child(3) {
      width: 15vw;
      background-color: #ffefd5;
    }

  }

  .row-content {
    z-index: 8;
  }

  .row-sponsors {
    background-color: white;
    border-top: 2px solid $grey;
    width: 100%;
    padding: 8px 20px;
  }
}


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

  .app {

    max-width: 1200px;
    width: auto;
    margin: 0 auto;

    .row-logo,
    .row-menu.browser,
    .row-content,
    .row-footer {
      max-width: 1200px;
      z-index: 1;
    }

    .row-content {
      margin-top: 110px
    }

    .row-menu.mobile,
    .row-info {
      display: none;
    }

    .row-logo-strip {
      height: 90px;
      border-bottom: none;
      background: none;

      .row-logo {
        background-color: white;
        .left {
          float: left;
          height: 100%;
          width: 50%;
          background-image: url(../assets/banner_background.jpg);
          background-position: bottom;
          background-repeat: repeat-x;
        }
        .right {
          float: right;
          height: 100%;
          width: 50%;
          background-image: url(../assets/banner_background.jpg);
          background-position: bottom;
          background-repeat: repeat-x;
          transform: scaleX(-1);
        }
        max-width: 1200px;
        height: 90px;
        border-bottom: 2px solid #b0b0b0;
      }
    }


    .row-menu.browser {
      position: fixed;
      top: 0px;
      width: 100%;
      height: 120px;
      display: flex;
      padding: 0 24px;

      > .search {
        width: 406px;
        min-width: 406px;
        height: 100%;
        display: flex;
        z-index: 20;

        > .logo {
          width: 66px;
          display: flex;
          justify-content: flex-start;

          > img {
            max-width: 62px;
          }
        }

        > .info {
          padding-left: 36px;

          .title-and-date {
            height: auto;

            h2 {
              font-family: 'Montserrat', sans-serif;
              color: $titleRed;
              text-transform: uppercase;
              font-weight: bold;
              font-size: 1.4em;
              margin-top: 18px;
              margin-bottom: 6px;
              text-align: right;
              white-space: nowrap;
            }

            p {
              font-family: 'Montserrat', sans-serif;
              color: #1d1d1b;
              margin-top: 0;
              text-transform: uppercase;
              font-size: .7em;
              text-align: right;
            }
          }


          .browser-search.search-wrapper {
            margin: 0;
            input {
              font-size: .8em;
              padding: .2em 35px .2em 10px;
              border-radius: 4px;
              background-size: 14px 14px;
            }
          }
        }
      }

      > .main-menu {
        width: calc(100% - 370px);
        max-width: calc(100% - 370px);
        //width: 820px;
        padding-left: 20px;
        height: 30px;
        z-index: 10;
      }
    }

    .row-menu.browser.small {
      height: 75px;

      > .main-menu {
        height: 100%;
        display: flex;
        align-items: center;
        .level-0.container {
          margin-top: 0;
        }
      }

      > .search {

        > .logo {
          > img {
            max-width: 48px;
          }
        }

        > .info {

          width: 343px;

          .title-and-date {
            display: none;
          }
          .search-wrapper {
            padding-top: 20px;
          }
        }
      }
    }
  }
}
.loading-bar {
  position: fixed;
  height: 3px !important;
  z-index:100;
  div {
    z-index:100;
  }
}