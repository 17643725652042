$backgroundColor: #ffefd5;

$logoTextColor: #05514e;


/* mobile css*/
.app .row-footer {

  background-color: $backgroundColor;
  padding: 12px;

  td, div {
    font-family: 'Montserrat', sans-serif;
  }

  .footer-content {

  }


  .footer-bottom {
    display: flex;
    flex-direction: column-reverse;
    color: $logoTextColor;


    .footer-logo {
      margin: 0 auto;
      display: table-row;
      width: 45%;
      height: 100%;
      align-items: center;

      .logo {
        display: table-cell;
        width: 20%;
        min-height: 40px;
        align-items: center;
        //background-image: url('../assets/icons/logo_bottom.svg');
        //background-repeat: no-repeat;
        //background-position: center;
        //background-size: 40px;
        img {
          height: 100%;
          max-height: 45px;
        }

      }

      .copyright {
        display: table-cell;
        padding: 0 4px;
        width: 70%;
        font-size: .5em;
        text-align: center;
        text-transform: uppercase;
      }
    }


    .footer-menu {
      width: 100%;
      min-height: 40px;

      > div {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        padding: 0;
        justify-content: space-around;
        align-items: center;
        white-space: nowrap;

        > div {
          padding: 0 4px;
          font-size: .7em;
        }
      }



    }
  }


}





/* desktop css */
@media only screen and (min-width: 900px) {

  .app .row-footer {

    .footer-bottom {
      flex-direction: row;

      .footer-logo {
        .copyright {
          font-size: .7em;
        }
      }

      .footer-menu {
        width: 65%;
        min-height: 60px;

        > div {
          display: flex;
          flex-wrap: wrap;
          height: 100%;
          padding: 0;
          justify-content: space-around;
          align-items: center;
          white-space: nowrap;

          > div {
            padding: 0 4px;
            font-size: 1em;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 900px) and (max-width: 1070px) {
  .footerMenuList {
    width: 50%;
  }
}
@media only screen and (max-width: 690px) {
  .footerMenuList {
    width: 50%;
  }
}
.footerMenuList {
  list-style: none;
  align-self: flex-start;
  padding-left: 8px;
  li {
    font-size: 17px;
    margin-bottom: .5rem;
  }
  li:not(:first-child) div {
    font-size: 13px;
    padding-left: 10px;
    padding-top: 5px;
  }
}