
.frontpage {

  h2.with-icon {
    padding-right: 50px;
    background-image: url(../assets/icons/move-right.svg);
    background-size: 1.1em;
    background-position: right 4px center;
    background-repeat: no-repeat;
  }

  .category-link:hover {
    text-decoration: underline;
  }

  .prequel {

  }

  .news-boxes {
    margin-top: 16px;
  }

  .recommendation-boxes {
    margin-top: 16px;
  }

  .sequel {

  }
}

.frontpage.edit {
  .label {
    font-weight: bold;
    padding-bottom: 12px;
    margin-top: 44px;
  }

  .fake-news {
    display: flex;
    justify-content: space-evenly;
  }

  .box-edit {
    .box-container-edit-item {
      background-color: white;
      border: 1px solid #9c9b9b;
      border-radius: 8px;
      width: 100%;
      min-height: 60px;
      padding: 14px 14px;
      margin-bottom: 40px;

      .item-header {

        height: 44px;
        display: flex;
        width: 100%;

        .item-title {
          width: 90%;
          font-size: 1.3em;
          font-weight: bold;
          text-align: left;
          padding-left: 20px;
        }

        .item-control {
          width: 10%;
          display: flex;
          align-items: flex-start;

          > div {
            width: 50%;
          }
        }
      }

      .item-boxes {
        display: flex;
        width: 100%;
        justify-content: space-between;

        > div {
          border: 1px solid #9c9b9b;
          border-radius: 8px;
          width: 32%;
          background-color: #ffefd5;
          height: 180px;
          background-image: url('../assets/icons/box-content-x.svg');
          background-position: center center;
          background-size: 90% 90%;
          background-repeat: no-repeat;
        }
      }
    }
  }
}