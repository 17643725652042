.edit-single-tag {

  .react-autosuggest__suggestions-container .drop-shadow {
    width: 100% !important;
    position: absolute;
  }
  
  .color-palette-label {
    font-weight: bold;
    margin-bottom: 16px;
  }

  .color-selector-wrapper {

    display: flex;
    margin-left: 20px;
    margin-bottom: 32px;

    .palette {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 300px;
      padding: 30px 20px 20px 20px;
      border: 1px solid #cdcdcd;
      border-radius: 10px;

      .color {
        border: 1px solid #cdcdcd;
        border-radius: 6px;
        width: 30px;
        height: 30px;
        margin-left: 10px;
        margin-bottom: 10px;
      }
      .color.no-color {
        background: repeating-linear-gradient(
                      -45deg,
                      rgba(0, 0, 0, 0),
                      rgba(0, 0, 0, 0) 49.49%,
                      rgba(205, 205, 205, 1) 49.5%,
                      rgba(205, 205, 205, 1) 51%,
                      rgba(0, 0, 0, 0) 51.01%,
                      rgba(0, 0, 0, 0)
        );
      }
    }

    .selected-color {
      margin-top: 30px;
      margin-left: 70px;
      width: 110px;
      height: 30px;
      border: 1px solid #cdcdcd;
      border-radius: 6px;
    }
    .selected-color.no-color {
      background: repeating-linear-gradient(
                      -12.5deg,
                      rgba(0, 0, 0, 0),
                      rgba(0, 0, 0, 0) 49.49%,
                      rgba(205, 205, 205, 1) 49.5%,
                      rgba(205, 205, 205, 1) 51%,
                      rgba(0, 0, 0, 0) 51.01%,
                      rgba(0, 0, 0, 0)
      )


    }
  }
}