
.search {

  .buttons {
    text-align: right;
    margin-bottom: 24px;
    margin-right: 30px;
  }

  .sub-title {
    font-size: 1.9em;
    font-weight: bold;
    font-style: italic;
    margin: 0 -46px;
    padding: 12px 46px;
    background-color: #ffefd5;
  }

  .found-productions,
  .found-tag-views,
  .found-articles {

    padding: 0 30px;

    .item {
      margin-bottom: 3em;
    }

    a {
      color: #2c1608;
    }

    h3 {
      font-size: 1.4em;
      margin-bottom: .4em;
    }

    .link-icon {
      padding-right: 50px;
      background-image: url(../assets/icons/move-right.svg);
      background-size: 1.2em;
      background-position: right 4px center;
      background-repeat: no-repeat;
    }

    .image {
      position: relative;
      text-align: center;
      width: 100%;
      margin-top: 12px;
      margin-bottom: 12px;

      .exclamation {
        position: absolute;
        top: 0;
        left: 0;
        width: 2em;
        height: 2em;
        background-image: url(../assets/icons/exclamation.svg);
        background-repeat: no-repeat;
        //background-size: 95;
      }

      .stop {
        position: absolute;
        top: 0;
        right: 0;
        width: 2em;
        height: 2em;
        background-image: url(../assets/icons/stop.svg);
        background-repeat: no-repeat;
      }

      img {
        border: 1px solid #9c9c9c;
        border-radius: 10px;
        width: 100%;
        margin: 0 0 0 0;
        padding: 0;
        min-width: 256px;
      }
    }

    .prod-status {
      margin-right: 12px;
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;

      span {
        margin-right: 12px;
        margin-bottom: 12px;
      }
    }
  }
}

@media only screen and (min-width: 992px) {

  .search {

    .buttons {
      margin-right: 0;
    }

    .sub-title {
      margin: 0 -54px;
      padding: 12px 54px;
    }

    .found-productions,
    .found-tag-views,
    .found-articles {
      .container {
        display: flex;
      }

      .image {
        width: 256px;
      }

      .info {
        padding-left: 12px;
      }

    }


  }
}

h2 {
  font-size: 1.3em;
  font-weight: bold;
  margin-bottom: 5px;
  padding: 0;
  margin-top: 8px;
}
.searchbox-labels .labels > span {
  margin-bottom: 8px;
  margin-right: 12px;
}
.app .row-content {
  .read {
    padding: 8px 24px;
  }
  .sequel {
    padding: 8px 24px;
  }
  .prequel {
    padding: 8px 24px;
  }
}
@media (max-width:768px) {
  .searchbox-labels .labels {
    display: block;
  }
  .searchbox-labels .labels:after {
    display: table;
    content: '';
    clear: both;
  }
  .searchbox-labels .labels > span {
    display: block;
    float: left;
  }
}
@media only screen and (min-width: 992px) {
    .flex {
      display: flex;
      justify-content: flex-start;
      gap: 30px;
    }
    .checkbox {
      display: inline-flex;
      cursor: pointer;
      position: relative;
      font-weight: bold;
    }
    .row {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        border-radius: 8px;
        .half {
            width: 50%;
            margin-bottom: 8px;
        }
        &.left {
            padding-right: 8px;
        }
        &.right {
            padding-left: 8px;
        }
    }
}
.inline {
    display: inline-block;
    margin-right: 12px;
}
.vertical-middle {
  vertical-align: text-bottom;
  padding: 1px;
}