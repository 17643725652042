$toolbarGreen: #05514e;
$toolbarGrey: #dadada;
$toolbarDarkGrey: #b1b1b1;

$publishDisabledBgColor: #d9d9d9;
$publishDisabledPublishedBgColor: #66cc99;
$publishDisabledUnpublishedBgColor: #cc9999;
$publishPublishedBgColor: #008d36;
$publishUnpublishedBgColor: #e5332a;


.toolbar-container {

  z-index: 500;
  position: fixed;
  display: flex;
  flex-direction: column;
  user-select: none;

  .toolbar-header {
    background-color: $toolbarGreen;
    height: 18px;
    border: 1px solid $toolbarGreen;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: grab;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    z-index: 502;

    > div {
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background-color: white;
    }
  }

  .toolbar-underlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    z-index: 501;
  }

  .toolbar-underlay.grabing {
    cursor: grabbing;
    z-index: 2000;
  }

  .toolbar-icons {
    display: flex;
    align-items: flex-start;
    height: 40px;
    border: 1px solid $toolbarGreen;
    z-index: 503;

    .icon {
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      background-color: white;
      border-right: 1px solid $toolbarGreen;
      border-bottom: 1px solid $toolbarGreen;
    }
    .icon:last-child {
      border-right: none;
    }

    .icon.disabled,
    .icon.disabled:hover {
      background-color: white;
      cursor: not-allowed !important;
    }

    .icon:hover,
    .active {
      background-color: $toolbarGrey;
    }

    .small {
      width: 42px;
      height: 40px;
    }

    .large {
      width: 72px;
      height: 40px;
    }


    .icon.edit {
      background-image: url('../assets/icons/edit.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 70%;
    }


    .icon.settings {
      background-image: url('../assets/icons/settings.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 70%;
    }

    .icon.settings:hover {
      background-image: url('../assets/icons/settings_hover.svg');
    }


    .icon.add {
      background-image: url('../assets/icons/toolbar_add.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 70%;
    }

    .icon.add:hover {
      background-image: url('../assets/icons/toobar_add_hover.svg');
    }


    .icon.publish .bg,
    .icon.publish.disabled .bg {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 50px;
      height: 24px;
      background-color: $publishDisabledBgColor;
      border-radius: 12px;
    }

    .icon.publish .bg.published {
      justify-content: flex-end;
      background-color: $publishPublishedBgColor;
    }

    .icon.publish.disabled .bg.published {
      justify-content: flex-end;
      background-color: $publishDisabledPublishedBgColor;
    }

    .icon.publish .bg.unpublished {
      background-color: $publishUnpublishedBgColor;
    }

    .icon.publish.disabled .bg.unpublished {
      background-color: $publishDisabledUnpublishedBgColor;
    }

    .icon.publish .bg > div {
      width: 18px;
      height: 18px;
      background-color: white;
      border-radius: 50%;
      margin: 0 4px;
    }

    .icon.logout {
      display: flex;

      > div {
        height: 100%;
        width: 50%;
      }

      > div:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 28px;
          height: 28px;
          border-radius: 50%;
        }
      }

      > div:last-child {
        background-image: url('../assets/icons/toolbar_logout.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50%;
      }
    }

  }



  .toolbar-sub-menu {
    background-color: $toolbarGrey;
    border: 1px solid $toolbarGreen;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    z-index: 504;

    > .item {
      padding: 6px;
      background-color: $toolbarGrey;
      color: $toolbarGreen;
      text-transform: lowercase;
    }
    > .item:hover {
      color: white;
      background-color: $toolbarDarkGrey;
    }
  }



  .toolbar-item.avatar {
    img {
      max-width: 50px;
      max-height: 50px;
    }
  }

}
.toolbar-container .toolbar-icons .icon.settings.ticketed {
  background-image: unset;
}
.toolbarIcon {
  height: 30px;
}