.news-archive {
  .prequel {

  }

  .news-boxes {
    margin-top: 16px;
  }

  .paginator {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    > div {

    }

    > div:first-child {
      margin-right: 22px;
    }

    > div:last-child {
      margin-left: 22px;
    }

    .icon {
      background-repeat: no-repeat;
      background-position: center;
    }
    .icon.prev {
      background-image: url('../assets/icons/move-left.svg');
    }
    .icon.prev:hover {
      background-image: url('../assets/icons/move-left-hover.svg');
    }
    .icon.next {
      background-image: url('../assets/icons/move-right.svg');
    }
    .icon.next:hover {
      background-image: url('../assets/icons/move-right-hover.svg');
    }
    .icon.prev.disabled {
      background-image: url('../assets/icons/move-left-inactive.svg');
    }
    .icon.next.disabled {
      background-image: url('../assets/icons/move-right-inactive.svg');
    }


  }

  .sequel {

  }

  .strip {
    height: 60px;
    min-width: 100%;
    margin: 12px -24px 42px;
    background-color: #f9d1aa;
  }
}

/*
.frontpage.edit {
  .label {
    font-weight: bold;
    padding-bottom: 12px;
    margin-top: 44px;
  }

  .box-edit {
    .box-container-edit-item {
      background-color: white;
      border: 1px solid #9c9b9b;
      border-radius: 8px;
      width: 100%;
      min-height: 60px;
      padding: 14px 14px;
      margin-bottom: 40px;

      .item-header {

        height: 44px;
        display: flex;
        width: 100%;

        .item-title {
          width: 90%;
          font-size: 1.3em;
          font-weight: bold;
          text-align: left;
          padding-left: 20px;
        }

        .item-control {
          width: 10%;
          display: flex;
          align-items: flex-start;

          > div {
            width: 50%;
          }
        }
      }

      .item-boxes {
        display: flex;
        width: 100%;
        justify-content: space-between;

        > div {
          border: 1px solid #9c9b9b;
          border-radius: 8px;
          width: 32%;
          background-color: #ffefd5;
          height: 180px;
          background-image: url('../assets/icons/box-content-x.svg');
          background-position: center center;
          background-size: 90% 90%;
          background-repeat: no-repeat;
        }
      }
    }
  }
}*/